import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('video_player', (uid, pause_when_others_play, jwoptions) => ({
  uid: uid,
  pause_when_others_play: pause_when_others_play,
  playerid: `video_player_${uid}`,
  player: null,
  init () {
    const player_element = document.getElementById(this.playerid);
    this.player = jwplayer(player_element).setup(jwoptions);
    this.registerPlayer();
    this.attachEvents();
  },
  registerPlayer () {
    // The reels script (js/alpine/ReelsPlayer) defines a callback
    // function stored under the UID in window.frop_players to be
    // run here once the JWPlayer has initialised.
    if (window.frop_players === undefined) window.frop_players = {};
    if (window.frop_players_pause === undefined) window.frop_players_pause = {};
    const cb = window.frop_players[this.uid];
    window.frop_players[this.uid] = this.player;
    if (typeof cb === 'function') cb();
    if (this.pause_when_others_play) window.frop_players_pause[this.uid] = this.player;
  },
  attachEvents () {
    this.player.on('play', this.play.bind(this));
  },
  play (evt) {
    Object.keys(window.frop_players_pause).forEach(uid => {
      let player = window.frop_players_pause[uid];
      if (uid !== this.uid && player.getState() == 'playing') {
        player.pause();
      }
    });
  }
}));